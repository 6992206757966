import Head from 'next/head'
import Image from 'next/image'
import Link from 'next/link'
import { PortableText } from '@portabletext/react'
import imageUrlBuilder from '@sanity/image-url'
import client from '@lib/sanity'
import { defaultComponents } from '@lib/portableText'
import clsx from 'clsx'
import { FaArrowRight, FaArrowDown } from 'react-icons/fa'
import { Trans } from '@lingui/macro'
import { absPageUrl } from '@lib/utils'
import H1 from 'components/ui/H1'
import H2 from 'components/ui/H2'
import Header from 'components/ui/Header'
import Blockquote from 'components/Blockquote'
import PageBlocks from './PageBlocks'
import IndexSlider from 'components/IndexSlider'
import Share from 'components/Share'
import ButtonLink from 'components/ButtonLink'
import PageCard from 'components/PageCard'
import UNSearch from 'components/UNSearch'
import SidebarSection from 'components/ui/SidebarSection'
import SidebarHeading from 'components/ui/SidebarHeading'
import { useTranslations } from 'providers/TranslationsProvider'
import { useEffect } from 'react'

const Page = ({ page }) => {
  const { init, headLinks } = useTranslations()

  useEffect(() => {
    init(page)
  }, [page])

  return (
    <div className={page?.bgcolour?.title ? `bg-bg${page.bgcolour.title}` : ``}>
      <div className="container">
        <article className="md:pb-6">
          <Head>
            <title key="title">
              {`${page?.title} - ${process.env.NEXT_PUBLIC_SITE_TITLE}`}
            </title>
            <meta property="og:title" content={page?.title} key="og:title" />
            <link rel="canonical" href={absPageUrl(page)} key="canonical" />
            <meta property="og:url" content={absPageUrl(page)} key="og:url" />
            <meta property="og:type" content="website" key="og:type" />
            {page.mainImage && page.mainImage.asset && (
              <meta
                property="og:image"
                content={imageUrlBuilder(client)
                  .image(page.mainImage)
                  .width(600)
                  .url()}
                key="og:image"
              />
            )}
            {headLinks()}
          </Head>
          <Header>
            {page?.parent && (
              <p className="mb-4 text-center font-medium uppercase 2xl:mb-10">
                <Link href="/[[...slug]]" as={`/${page.parent.slug.current}`}>
                  <a>{page.parent.title}</a>
                </Link>
              </p>
            )}
            <H1>{page?.title}</H1>
          </Header>
          <div
            className={clsx(
              `content-start items-start justify-between`,
              page.mainImage ? `md:flex` : ``,
              page.mainImage &&
                page.pagelayout &&
                page.pagelayout === 'image-left'
                ? ``
                : `md:flex-row-reverse`
            )}
          >
            {page?.mainImage && (
              <div
                className={`mb-4 ${
                  page?.pagelayout && page?.pagelayout === 'image-left'
                    ? `md:basis-1/2`
                    : `md:basis-1/3`
                }`}
              >
                <div className="relative aspect-video w-full bg-primary-100">
                  <Image
                    src={imageUrlBuilder(client)
                      .image(page.mainImage)
                      .width(600)
                      .url()}
                    alt={page.title}
                    layout="fill"
                    objectFit="cover"
                    objectPosition="center top"
                  />
                </div>
                {page.mainImageCaption && (
                  <p className="text-sm text-primary-400">
                    {page.mainImageCaption}
                  </p>
                )}
                {page.mainImageCredit && (
                  <p className="text-sm text-primary-300">
                    {page.mainImageCreditLink ? (
                      <a
                        href={page.mainImageCreditLink}
                        target="_blank"
                        rel="noreferrer"
                        className="border-b-2 border-accent-400 text-primary-300 hover:text-primary-600"
                      >
                        {page.mainImageCredit}
                      </a>
                    ) : (
                      page.mainImageCredit
                    )}
                  </p>
                )}
                {page.links && (
                  <div className="mt-10 hidden lg:block">
                    <SidebarSection>
                      <SidebarHeading>
                        <Trans>Links</Trans>
                      </SidebarHeading>
                      <ul>
                        {page.links.map((link) => (
                          <li key={link._key}>
                            <a
                              className="border-b-2 border-accent-500"
                              href={link.url}
                            >
                              {link.title}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </SidebarSection>
                  </div>
                )}
                <Share
                  shareUrl={`${process.env.NEXT_PUBLIC_DOMAIN}/${page.slug.current}`}
                  title={page.title}
                />
              </div>
            )}
            <div
              className={clsx(
                `pb-10`,
                page?.mainImage
                  ? page.pagelayout && page.pagelayout === 'image-left'
                    ? `md:basis-1/2 md:pl-10`
                    : `md:basis-2/3 md:pr-10`
                  : `max-w-3xl md:mx-auto`
              )}
            >
              <div className="prose-primary-500 prose lg:prose-xl">
                <PortableText
                  value={page?.body}
                  components={defaultComponents}
                />
              </div>
            </div>
            {page.links && (
              <div className="lg:hidden">
                <SidebarSection>
                  <SidebarHeading>
                    <Trans>Links</Trans>
                  </SidebarHeading>
                  <ul>
                    {page.links.map((link) => (
                      <li key={link._key}>
                        <a
                          className="border-b-2 border-accent-500"
                          href={link.url}
                        >
                          {link.title}
                        </a>
                      </li>
                    ))}
                  </ul>
                </SidebarSection>
              </div>
            )}
          </div>
          {page.childPages && (
            <div className="grid grid-cols-2 gap-4 md:grid-cols-3 md:gap-8 lg:grid-cols-4">
              {page.childPages.map((childPage) => (
                <div
                  key={childPage._id}
                  className="flex flex-row justify-center"
                >
                  <PageCard page={childPage} />
                </div>
              ))}
            </div>
          )}
          {!page.mainImage && (
            <Share
              shareUrl={`${process.env.NEXT_PUBLIC_DOMAIN}/${page.slug.current}`}
              title={page.title}
            />
          )}
        </article>
      </div>
      {page?.related && page?.related.length > 0 && (
        <div className="mb-8">
          <div className="container">
            <H2>
              <Trans>Latest items</Trans>
            </H2>
          </div>
          <IndexSlider posts={page.related} />
          {page.subject && page.subject.length === 1 && (
            <div className="mt-4 flex justify-center md:mt-8 lg:mt-10">
              <ButtonLink
                href={{
                  pathname: '/search',
                  query: { subject: page.subject[0]._ref },
                }}
              >
                <span className="mr-2 leading-tight">
                  <Trans>Explore more content</Trans>
                </span>
                <FaArrowRight />
              </ButtonLink>
            </div>
          )}
        </div>
      )}
      <div className="container">
        {(page.slug.current === 'our-work/un-level-engagement' ||
          page.slug.current === 'notre-travail/engagement-nations-unies' ||
          page.slug.current ===
            'nuestro-trabajo/incidencia-a-nivel-del-sistema-de-la-onu') && (
          <UNSearch />
        )}
        <PageBlocks page={page} />
        {page.quotation && (
          <Blockquote cite={page.quotationCitation} url={page.quotationUrl}>
            {page.quotation}
          </Blockquote>
        )}
      </div>
    </div>
  )
}
export default Page
