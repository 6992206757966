import { useRouter } from 'next/router'
import { toPlainText } from '@portabletext/react'
import TextTruncate from 'react-text-truncate'
import imageUrlBuilder from '@sanity/image-url'
import Image from 'next/image'
import Link from 'next/link'
import { format } from 'date-fns'
import { formatDate, isSameDate } from '@lib/i18n'
import client from '@lib/sanity'
import { HiDocumentText } from 'react-icons/hi'
import { FaLocationArrow, FaCalendarAlt, FaAt } from 'react-icons/fa'
import { BsPinAngleFill } from 'react-icons/bs'
import clsx from 'clsx'
import PostType from 'components/PostType'

const postLinkParams = (post) => {
  const publishedAt = new Date(post.publishedAt)
  let year = new Date().getFullYear()
  try {
    year = format(publishedAt, 'yyyy')
  } catch (err) {
    console.error(post.title)
  }
  return {
    href: '/[[...slug]]',
    as: `/${post.type}/${year}/${format(publishedAt, 'MM')}/${
      post.slug.current
    }`,
  }
}

const PostLink = ({ post, children }) => {
  if (post.type === 'media' && post.source) {
    return (
      <a href={post.source} target="_blank" rel="noreferrer">
        {children}
      </a>
    )
  }
  const params = postLinkParams(post)
  return (
    <Link href={params.href} as={params.as}>
      <a>{children}</a>
    </Link>
  )
}

const PostTeaser = ({
  post,
  excerpt = true,
  showType = true,
  large = false,
}) => {
  const router = useRouter()
  const params = postLinkParams(post)

  return (
    <article
      className={clsx(
        `border-b border-primary-100 pb-4 md:pb-8 lg:pb-10`,
        large
          ? `md:col-span-2 md:row-span-2 md:flex md:flex-col md:bg-primary-100 md:p-8`
          : ``
      )}
    >
      <div className="mb-2 text-sm text-primary-300">
        {post.sticky && (
          <div className="mr-1 inline-block">
            <BsPinAngleFill />
          </div>
        )}
        {showType && (
          <span className="mr-1 hidden uppercase md:inline-block">
            <PostType post={post} />
          </span>
        )}
        {post.type !== 'events' &&
          formatDate(new Date(post.publishedAt), 'd MMMM yyyy', router.locale)}
      </div>
      {post.mainImage && (
        <div className="">
          <PostLink post={post}>
            <span className="relative mb-4 block aspect-video w-full bg-primary-100">
              {post.mainImage.asset ? (
                <Image
                  src={imageUrlBuilder(client)
                    .image(post.mainImage)
                    .width(600)
                    .url()}
                  alt={post.title}
                  layout="fill"
                  objectFit="cover"
                  objectPosition="center top"
                />
              ) : (
                <span className="content-stretch flex h-full items-center justify-center text-6xl text-primary-200">
                  <HiDocumentText />
                </span>
              )}
            </span>
          </PostLink>
        </div>
      )}
      <div className={clsx(large ? `mt-8` : ``)}>
        <h1
          className={
            large
              ? `mb-4 font-medium leading-tight lg:text-3xl`
              : `mb-2 font-medium leading-tight lg:text-xl`
          }
        >
          <PostLink post={post}>{post.title}</PostLink>
        </h1>

        {post.type === 'events' && (
          <div className="flex flex-wrap">
            {post.eventLocation && (
              <div className="mb-2 mr-2 flex items-center rounded bg-accent-200 py-0.5 px-2 text-sm">
                {post.eventLocation === 'Online' ||
                post.eventLocation === 'Webinar' ? (
                  <FaAt className="mr-2 text-primary-400" />
                ) : (
                  <FaLocationArrow className="mr-2 text-primary-400" />
                )}
                {post.eventLocation}
              </div>
            )}
            {post.eventStart && post.eventEnd ? (
              <>
                {isSameDate(post.eventStart, post.eventEnd) ? (
                  <>
                    {post.eventStart && (
                      <div className="mb-2 flex items-center rounded bg-accent-200 py-0.5 px-2 text-sm">
                        <FaCalendarAlt className="mr-2 text-primary-400" />
                        <span>
                          {formatDate(
                            new Date(post.eventStart),
                            'd MMMM yyyy',
                            router.locale
                          )}
                        </span>
                      </div>
                    )}
                  </>
                ) : (
                  <div className="mb-2 flex items-center rounded bg-accent-200 py-0.5 px-2 text-sm">
                    <FaCalendarAlt className="mr-2 text-primary-400" />
                    <span>
                      {formatDate(
                        new Date(post.eventStart),
                        'd MMMM yyyy',
                        router.locale
                      )}
                      {' - '}
                      {formatDate(
                        new Date(post.eventEnd),
                        'd MMMM yyyy',
                        router.locale
                      )}
                    </span>
                  </div>
                )}
              </>
            ) : null}
          </div>
        )}

        {excerpt && (
          <div className="grow-0">
            <TextTruncate
              line={post.type === 'events' ? 3 : 5}
              element="span"
              truncateText="…"
              text={
                post.excerpt
                  ? post.excerpt
                  : post.body
                  ? toPlainText(post.body)
                  : ''
              }
            />
          </div>
        )}
      </div>
    </article>
  )
}
export default PostTeaser
